<template lang='pug'>
v-row
  v-container.my-10(fill-height fluid v-if="!loggedin")
    v-row(align="center" justify="center")
      v-col(cols="12", sm="8", md="4")
        v-card.elevation-12
          v-toolbar(color="primary", dark, flat)
            v-toolbar-title Power Trip Account Log In
          v-card-text
            v-form(ref="form", v-model="valid", lazy-validation)
              v-text-field(
                label="Your Email",
                name="email",
                prepend-icon="mdi-email",
                type="text",
                v-model="email",
                autocomplete="username",
                :rules="[rules.required, rules.email]"
              )
              v-text-field#password(
                label="Password",
                name="password",
                prepend-icon="mdi-lock",
                type="password",
                v-model="password",
                autocomplete="current-password",
                :rules="[rules.required, rules.minimum]"
              )
            v-card-actions
              v-spacer
              v-btn(text, @click="signup") Create New Account
              v-btn(color="primary", @click="login") Log In
    v-snackbar(v-model="snackbar", :timeout="4000") {{ messageText }}
</template>

<script>
import PowerTrip from "../services/api/PowerTrip.js";
import Vue from 'vue';
import axios from 'axios';
export default {
  data() {
    return {
      messageText: "",
      snackbar: false,
      valid: true,
      loggedin: false,
      email: "",
      password: "",
      rules: {
        required: (value) => !!value || "Required.",
        minimum: (value) => value.length >= 8 || "Minimum 8 Characters",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    localStorage.removeItem("servicecode");
    localStorage.removeItem("credentials");
  },
  methods: {
        signup() {
      this.$router.push("/new");
    },
    async login() {
      if (this.$refs.form.validate()) {
        var data = {
          email: this.email,
          password: this.password,
        };
          var result = await PowerTrip.login(data);
          if (result.id) {
            // store token locally
            Vue.prototype.$user = result;
           // Vue.prototype.$auth = data;
            localStorage.setItem("token",result.token);
            axios.defaults.headers.common["X-Auth-Powertrip"] = result.token;
            this.$router.push("/");

        } else {
          this.snackbar = true;
          this.messageText =
            "Unable to log in with these details.";
        }
      }
    },
  },
};
</script>